<template>
    <el-dialog
        title="提交申请"
        :visible.sync="addApplicationSubmitVisible"
        width="30%"
        :before-close="handleClose">
        <div class="content">
            <div>确定提交新增申请吗?</div>
            <div style="margin-top: 10px">审核通过后，次月可开始相关服务</div>
        </div>
        <div class="content">
            未配置XXXX的补贴审批流程，请联系管理员进行配置！
        </div>
        <span slot="footer" class="dialog-footer">
    <el-button @click="handleClose">取 消</el-button>
    <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
     <el-button type="primary" @click="handleClose">知道了</el-button>
  </span>
    </el-dialog>
</template>

<script>
  export default {
    name: "addApplicationSubmit",
    props: {
      addApplicationSubmitVisible: {
        type: Boolean,
        default: false
      }
    },
    methods: {
      handleClose() {
        this.$emit('update:addApplicationSubmitVisible', false)
      }
    }
  }
</script>

<style scoped>
    .content {
        padding: 20px 50px;
    }
</style>
